import {useAppDispatch} from "hooks";
import {Link, useNavigate} from "react-router-dom";
import {appLoading} from "Store/loadingSlice";
import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {useMutation, useQuery} from "react-query";
import {getCenters} from "Api/Query/CenterQuery";
import {Notificator} from "Services/Notificator/Notificator";
import {deleteActivities} from "Api/Mutation/ActivityMutation";
import Pagination from "rc-pagination";

interface Props {
    title: string,
    headers: string[],
    queryMethod: Function,
    queryName: string,
    deleteMethod: Function,
    createButton?: React.ReactNode,
    tableRow: (item: any, key: number) => React.ReactNode
    notFoundMessage?: string
    canSelectMultipleRows?: boolean,
    refreshSwitch?: boolean,
    customFilters?: React.ReactNode
    customFiltersData?: { [key: string]: any },
    pageSize?: number,
    exportChildren?: React.ReactNode
}

export const AdminCrudList: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch()

    const canSelectMultipleRows = props.canSelectMultipleRows ?? true
    const [allSelected, setAllChecked] = useState<boolean>(false)
    const [selectedRecords, setSelectedRecords] = useState<{ [key: string]: boolean }>({})
    const [records, setRecords] = useState<any[]>([])
    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    const [pageSize, setPageSize] = useState<number>(props.pageSize ?? 12)
    const [page, setPage] = useState<number>(1)

    const [searchFilters, setSearchFilters] = useState<{ [key: string]: any }>({
        size: pageSize,
        page: page
    })
    const [searchFilterValue, setSearchFilterValue] = useState<string>('')
    const [totalRecords, setTotalRecords] = useState<number>(0)

    const deleteMutation = useMutation({
        mutationKey: ['deleteCrud'],
        mutationFn: (ids: any) => props.deleteMethod(ids),
        onSuccess: () => {
            setIsDeleting(false)
            setSelectedRecords({})
            query.refetch()
            Notificator.success('Los registros seleccionados se han eliminado correctamente')
        },
        onError: () => {
            setIsDeleting(false)
            Notificator.error('Ha ocurrido un error al intentar eliminar los registros seleccionados.')
        }
    })

    const checkAll = () => {
        if (allSelected) {
            setSelectedRecords(initCheckboxes(records))
        } else {
            const checkboxes = selectedRecords
            Object.keys(checkboxes).map(key => checkboxes[key] = true)
            setSelectedRecords(checkboxes)
        }

        setAllChecked(!allSelected)
    }

    const isAnyRowChecked = (): boolean => {
        for (let key in Object.values(selectedRecords)) {
            const isChecked = Object.values(selectedRecords)[key]

            if (isChecked) {
                return true
            }
        }

        return false
    }

    const getCheckedIds = (): string[] => {
        const ids: string[] = []

        const keys = Object.keys(selectedRecords)
        const values = Object.values(selectedRecords)

        for (let i in values) {
            const checked = values[i]
            if (checked) {
                ids.push(keys[i])
            }
        }

        return ids
    }

    const onSelectedCheckboxRowChange = (item: any) => {
        setSelectedRecords({
            ...selectedRecords,
            [item.id]: !selectedRecords[item.id]
        })
    }

    const initCheckboxes = (items: any) => {
        const checkboxes: { [key: string]: boolean } = {}
        items.map(item => checkboxes[item.id] = false)
        return checkboxes
    }

    const deleteMultiple = () => {
        if (!window.confirm('¿Deseas eliminar los registros seleccionados?')) {
            return
        }

        setIsDeleting(true)
        deleteMutation.mutate(getCheckedIds())
    }

    const query = useQuery({
        enabled: false,
        queryKey: [props.queryName],
        queryFn: () => props.queryMethod(searchFilters),
        onSuccess: (response: any) => {
            const responseData = response.data._result
            const recordsResp = responseData.items
            setRecords(recordsResp)
            setTotalRecords(responseData.total)
            setSelectedRecords(initCheckboxes(recordsResp))
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const onEnterPressed = (event: React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }

        if (searchFilterValue) {
            setSearchFilters({'q': `*${searchFilterValue}*`})
        } else {
            setSearchFilters({})
        }

        dispatch(appLoading({value: true, text: 'Cargando'}))
    }

    const onPaginationPageChange = (selectedPage: number) => {
        setPage(selectedPage)
        setSearchFilters({
            ...searchFilters,
            page: selectedPage.toString()
        })
    }

    useEffect(() => {
        query.refetch()
    }, [])

    useEffect(() => {
        query.refetch()
    }, [searchFilters])

    useEffect(() => {
        dispatch(appLoading({value: query.isLoading, text: 'Cargando'}))
    }, [query.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: query.isRefetching, text: 'Cargando'}))
    }, [query.isRefetching])

    useEffect(() => {
        query.refetch()
    }, [props.refreshSwitch]);

    useEffect(() => {
        let f = props.customFiltersData
        f = {...f, page: page}
        f = {...f, size: pageSize}
        setSearchFilters(f)
    }, [props.customFiltersData]);

    return (
        <DefaultContainer>

            <Col id={"mainRequestList"}>

                <Row>
                    <Col xs={12} sm={8}>
                        <h1 className={"pb-4"}>
                            {props.title}
                        </h1>
                    </Col>
                    <Col xs={12} sm={4}>
                        <div className={"d-flex justify-content-end"}>

                            {
                                isAnyRowChecked() &&
                                <>
                                    <Button
                                        variant={"secondary"}
                                        onClick={deleteMultiple}
                                        disabled={isDeleting}
                                        className={"mx-2"}
                                    >
                                        {!isDeleting && <span>Eliminar seleccionados</span>}
                                        {isDeleting && <span>Eliminando ...</span>}
                                    </Button>
                                </>
                            }

                            {props.exportChildren}
                            {props.createButton}

                        </div>
                    </Col>
                </Row>

                <div className={"row my-4"}>
                    {
                        !props.customFilters && <Col xs={12} sm={6}>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Buscar
                                    &nbsp;<FormLabelRequiredAsterisk/>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e: any) => setSearchFilterValue(e.target.value)}
                                    onKeyDown={onEnterPressed}
                                />
                            </Form.Group>
                        </Col>
                    }
                    {props.customFilters && props.customFilters}
                </div>
                <br/>

                <table className={"table table-bordered redTable"}>
                    <thead>
                    <tr>
                        {
                            canSelectMultipleRows &&
                            <th
                                style={{"width": "2%"}}
                                className={"align-middle"}
                            >
                                <input
                                    type="checkbox"
                                    defaultChecked={allSelected}
                                    onChange={checkAll}
                                />
                            </th>
                        }
                        {props.headers.map((header, key) => <th className={"align-middle"} key={key}>{header}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        records.length === 0 &&
                        <tr>
                            <td colSpan={props.headers.length + 1} className={"px-4 py-4"}>
                                {props.notFoundMessage ?? 'No se han encontrado registros.'}
                            </td>
                        </tr>
                    }

                    {
                        records.map((item, key) => <tr key={key}>
                            <>
                                {
                                    canSelectMultipleRows &&
                                    <td className={"align-middle"}>
                                        <input
                                            key={key}
                                            type="checkbox"
                                            checked={selectedRecords[item.id]}
                                            onChange={() => onSelectedCheckboxRowChange(item)}
                                        />
                                    </td>
                                }
                                {props.tableRow(item, key)}
                            </>
                        </tr>)
                    }
                    </tbody>
                </table>

                <Row>
                    <Col>
                        {totalRecords > 0 &&
                            <Pagination
                                total={totalRecords}
                                pageSize={pageSize}
                                current={page}
                                onChange={onPaginationPageChange}
                            />
                        }
                    </Col>
                </Row>

            </Col>

        </DefaultContainer>
    )
}
