import {http} from "Services/Http/HttpClient";

export const addInscriptionObservation = async(
    inscriptionId: string,
    observation: string
) => {
    return await http.post(`inscriptions/${inscriptionId}/observation`, {
        observation: observation
    })
}

export const updateInscription = async(inscriptionId:string, body: any) => {
    return await http.patch(`inscriptions/${inscriptionId}/admin`, {
        status: body.status,
        inscriptionOrderDiscountType: body.inscriptionOrderDiscountType,
        inscriptionOrderActivitiesToDelete: body.inscriptionOrderActivitiesToDelete,
        socialWorker: body.socialWorker ?? null,
        rejectedReason: body.rejectedReason ?? null
    })
}
