import React from "react"
import {Col, Row} from "react-bootstrap"
import {Link} from "react-router-dom";
import logoFooter3 from "Assets/images/footer-img-3.png";
import logoSanseWhite from "Assets/images/logo-sanse-white.png";
import lema from "Assets/images/Lema_RRSS_blanco-300x130.png";
import {PrivacyPolicyLink} from "Components/Link/PrivacyPolicyLink";
import {LegalWarningLink} from "Components/Link/LegalWarningLink";

export const Footer: React.FC = () => {

    return (
        <Row id={"footer"}>
            <Col className={"col-12 col-md-10 mx-auto"}>
                <Row>
                    <Col className={"col-12 col-sm-6 col-lg-3 mx-auto"}>
                        <span className={"heading"}>Sanse Concilia</span>
                        <span>Concejalía de Educación del Ayuntamiento de San Sebastián de los Reyes</span>
                        <LegalWarningLink /> - <PrivacyPolicyLink />
                    </Col>
                    <Col className={"col-12 col-sm-6 col-lg-3 mx-auto"}>
                        <span className={"heading"}>Contacto</span>
                        <span>Si necesitan ponerse en contacto con nosotros para cualquier aclaración o duda se pueden dirigir a nosotros en:</span>
                        <span className={"font-weight-bold"}>Centro Sociocultural “Pablo Iglesias”</span>
                        <span>Avda. Baunatal, 18 , 4ª planta.Tel.: <Link
                            to={"tel:916588999"}>91 658 89 99</Link> - <Link
                            to={"916588993"}>91 658 89 93</Link></span>
                        <span>Email: <Link
                            to={"mailto:sanseconcilia@ssreyes.org"}>sanseconcilia@ssreyes.org</Link></span>
                    </Col>
                    <Col
                        id={"footerLogoLeft"}
                        className={"col-12 col-sm-6 col-lg-3 mx-auto"}>
                        <Row>
                            <Col className={"text-center"}>
                                <img src={logoSanseWhite} alt={"Logo"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={"text-center pt-4"}>
                                <img src={logoFooter3} alt={"Logo"}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        id={"footerLogoRight"}
                        className={"col-12 col-sm-6 col-lg-3 mx-auto"}>
                        <Row>
                            <Col className={"text-center"}>
                                <img src={lema} alt={"Logo"}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
