import React from "react";
import {useRoutes} from "react-router-dom";
import {Dashboard} from "Views/Dashboard/Dashboard";
import {Login} from "Views/Security/Login";
import {NotFound} from "Views/Router/NotFound";
import {Signup} from "Views/Security/Signup";
import {AccountActivation} from "Views/Security/AccountActivation";
import {ListActivities} from "Views/Activities/ListActivities";
import {ActivityDetail} from "Views/Activities/ActivityDetail";
import {Cart} from "Views/Cart/Cart";
import {InvoiceDetailRequest} from "Views/Requests/InvoiceDetailRequest";
import {RequestItemsList} from "Views/Requests/RequestItemsList";
import {RemoveRequest} from "Views/Requests/RemoveRequest";
import {UserDataUpdater} from "Views/User/Update/UserDataUpdater";
import {AdminUserList} from "Views/User/AdminUserList";
import {KidsList} from "Views/Kids/KidsList";
import {AddChild} from "Views/Kids/AddChild";
import {AdminKidsList} from "Views/Kids/AdminKidsList";
import {AdminActivitiesList} from "Views/Activities/AdminActivitiesList";
import {AdminAddActivity} from "Views/Activities/AdminAddActivity";
import {AdminModalityList} from "Views/Modalities/AdminModalityList";
import {AdminCenterList} from "Views/Center/AdminCenterList";
import {AdminIndividualNeedsList} from "Views/IndividualNeeds/AdminIndividualNeedsList";
import {AdminCreateModality} from "Views/Modalities/AdminCreateModality";
import {AdminEditActivity} from "Views/Activities/AdminEditActivity";
import {AdminCentersAdd} from "Views/Center/AdminCentersAdd";
import {AdminCentersEdit} from "Views/Center/AdminCentersEdit";
import {AdminEditModality} from "Views/Modalities/AdminEditModality";
import {EditChild} from "Views/Kids/EditChild";
import {PasswordRecovery} from "Views/Security/PasswordRecovery";
import {PasswordRecoveryDetail} from "Views/Security/PasswordRecoveryDetail";
import {AdminUserEdit} from "Views/User/AdminUserEdit";
import {AdminEditChild} from "Views/Kids/AdminEditChild";
import {AdminInscriptionList} from "Views/Inscription/AdminInscriptionList";
import {AdminInscriptionDetail} from "Views/Inscription/AdminInscriptionDetail";
import {AdminUserCreate} from "Views/User/AdminUserCreate";
import {LegalWarning} from "Views/Terms/LegalWarning";
import {PrivacyPolicy} from "Views/Terms/PrivacyPolicy";
import {AdminCourceList} from "Views/Course/AdminCourseList";
import {AdminCourseEdit} from "Views/Course/AdminCourseEdit";
import {AdminCourseCreate} from "Views/Course/AdminCourseCreate";
import {SocialServiceInscriptionDetail} from "Views/Inscription/SocialServiceInscriptionDetail";
import {AdminReportList} from "Views/Report/AdminReportList";
import {AdminTutorList} from "../../Views/Tutor/AdminTutorList";
import {Help} from "../../Views/Help/Help";

export const LEGAL_WARNING_DOC_URL = process.env.REACT_APP_FRONTEND_URL + "condiciones.pdf"

export const ROUTE_PATHS = {
    LOGIN: "/",
    SIGNUP: "/signup",
    HELP: "/help",
    LEGAL_WARNING: "/legal-warning",
    PRIVACY_POLICY: "/privacy-policy",
    SIGNUP_ACTIVATION: "/signup/activation/:token",
    PASSWORD_RECOVERY: "/password-recovery",
    PASSWORD_RECOVERY_DETAIL: "/password-recovery/detail/:token",
    DASHBOARD: "/dashboard",
    ACTIVITY_DETAIL: "/activities/:id",
    ACTIVITIES_LIST: "/activities/list",
    REQUEST_INVOICE_DETAIL: "/requests/invoice-detail",
    REQUEST_ITEMS_LIST: "/requests/items/list",
    REQUEST_REMOVE: "/requests/remove/:id",
    SHOPPING_CART: "/cart",
    TUTOR_DATA_UPDATER: "/user/data",
    KIDS_LIST: "/kids/list",
    KIDS_ADD: "/kids/add",
    KIDS_EDIT: "/kids/edit/:id",
    NOT_FOUND: "*",
    ADMIN_INSCRIPTION_DETAIL: "/admin/inscriptions/:id",
    ADMIN_SOCIAL_SERVICES_PROFILE_INSCRIPTION_DETAIL: "/admin/inscriptions/social-services/:id",
    ADMIN_TUTOR_LIST: "/admin/tutors",
    ADMIN_INSCRIPTION_LIST: "/admin/inscriptions",
    ADMIN_REPORT_LIST: "/admin/reports",
    ADMIN_KIDS_LIST: "/admin/kids",
    ADMIN_KIDS_EDIT: "/admin/kids/edit/:id",
    ADMIN_USER_LIST: "/admin/users",
    ADMIN_ACTIVITIES_LIST: "/admin/activities",
    ADMIN_ACTIVITIES_ADD: "/admin/activities/add",
    ADMIN_ACTIVITIES_EDIT: "/admin/activities/edit/:id",
    ADMIN_MODALITIES_LIST: "/admin/modalities",
    ADMIN_MODALITY_CREATE: "/admin/modalities/create",
    ADMIN_MODALITY_EDIT: "/admin/modalities/edit/:id",
    ADMIN_CENTERS_LIST: "/admin/centers",
    ADMIN_CENTERS_ADD: "/admin/centers/add",
    ADMIN_CENTERS_EDIT: "/admin/centers/edit/:id",
    ADMIN_SPECIAL_NEEDS_LIST: "/admin/special-needs",
    ADMIN_USER_EDIT: "/admin/user/edit/:id",
    ADMIN_USER_CREATED: "/admin/user/edit/create",
    ADMIN_COURSES_LIST: "/admin/courses",
    ADMIN_COURSES_EDIT: "/admin/courses/edit/:id",
    ADMIN_COURSES_CREATE: "/admin/courses/create",
}

export const AppRoutes = () => {
    return useRoutes([
        {path: ROUTE_PATHS.LOGIN, element: <Login/>},
        {path: ROUTE_PATHS.SIGNUP, element: <Signup/>},
        {path: ROUTE_PATHS.HELP, element: <Help/>},
        {path: ROUTE_PATHS.DASHBOARD, element: <Dashboard/>},
        {path: ROUTE_PATHS.NOT_FOUND, element: <NotFound/>},
        {path: ROUTE_PATHS.SIGNUP_ACTIVATION, element: <AccountActivation/>},
        {path: ROUTE_PATHS.ACTIVITIES_LIST, element: <ListActivities/>},
        {path: ROUTE_PATHS.ACTIVITY_DETAIL, element: <ActivityDetail/>},
        {path: ROUTE_PATHS.SHOPPING_CART, element: <Cart/>},
        {path: ROUTE_PATHS.REQUEST_INVOICE_DETAIL, element: <InvoiceDetailRequest/>},
        {path: ROUTE_PATHS.REQUEST_ITEMS_LIST, element: <RequestItemsList/>},
        {path: ROUTE_PATHS.REQUEST_REMOVE, element: <RemoveRequest/>},
        {path: ROUTE_PATHS.TUTOR_DATA_UPDATER, element: <UserDataUpdater/>},
        {path: ROUTE_PATHS.KIDS_LIST, element: <KidsList/>},
        {path: ROUTE_PATHS.KIDS_ADD, element: <AddChild/>},
        {path: ROUTE_PATHS.ADMIN_INSCRIPTION_DETAIL, element: <AdminInscriptionDetail/>},
        {path: ROUTE_PATHS.ADMIN_INSCRIPTION_LIST, element: <AdminInscriptionList/>},
        {path: ROUTE_PATHS.ADMIN_REPORT_LIST, element: <AdminReportList/>},
        {path: ROUTE_PATHS.ADMIN_KIDS_LIST, element: <AdminKidsList/>},
        {path: ROUTE_PATHS.ADMIN_USER_LIST, element: <AdminUserList/>},
        {path: ROUTE_PATHS.ADMIN_ACTIVITIES_LIST, element: <AdminActivitiesList/>},
        {path: ROUTE_PATHS.ADMIN_ACTIVITIES_ADD, element: <AdminAddActivity/>},
        {path: ROUTE_PATHS.ADMIN_ACTIVITIES_EDIT, element: <AdminEditActivity/>},
        {path: ROUTE_PATHS.ADMIN_MODALITIES_LIST, element: <AdminModalityList/>},
        {path: ROUTE_PATHS.ADMIN_CENTERS_LIST, element: <AdminCenterList/>},
        {path: ROUTE_PATHS.ADMIN_CENTERS_ADD, element: <AdminCentersAdd/>},
        {path: ROUTE_PATHS.ADMIN_CENTERS_EDIT, element: <AdminCentersEdit/>},
        {path: ROUTE_PATHS.ADMIN_SPECIAL_NEEDS_LIST, element: <AdminIndividualNeedsList/>},
        {path: ROUTE_PATHS.ADMIN_MODALITY_CREATE, element: <AdminCreateModality/>},
        {path: ROUTE_PATHS.ADMIN_MODALITY_EDIT, element: <AdminEditModality/>},
        {path: ROUTE_PATHS.KIDS_EDIT, element: <EditChild/>},
        {path: ROUTE_PATHS.ADMIN_USER_EDIT, element: <AdminUserEdit/>},
        {path: ROUTE_PATHS.ADMIN_USER_CREATED, element: <AdminUserCreate/>},
        {path: ROUTE_PATHS.PASSWORD_RECOVERY, element: <PasswordRecovery/>},
        {path: ROUTE_PATHS.PASSWORD_RECOVERY_DETAIL, element: <PasswordRecoveryDetail/>},
        {path: ROUTE_PATHS.ADMIN_KIDS_EDIT, element: <AdminEditChild/>},
        {path: ROUTE_PATHS.LEGAL_WARNING, element: <LegalWarning/>},
        {path: ROUTE_PATHS.PRIVACY_POLICY, element: <PrivacyPolicy/>},
        {path: ROUTE_PATHS.ADMIN_COURSES_LIST, element: <AdminCourceList/>},
        {path: ROUTE_PATHS.ADMIN_COURSES_EDIT, element: <AdminCourseEdit/>},
        {path: ROUTE_PATHS.ADMIN_COURSES_CREATE, element: <AdminCourseCreate/>},
        {path: ROUTE_PATHS.ADMIN_SOCIAL_SERVICES_PROFILE_INSCRIPTION_DETAIL, element: <SocialServiceInscriptionDetail/>},
        {path: ROUTE_PATHS.ADMIN_TUTOR_LIST, element: <AdminTutorList/>},
    ])
};
