import React from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {Button} from "react-bootstrap";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {Link} from "react-router-dom";
import {AdminCrudList} from "Views/Shared/Crud/AdminCrudList";
import {getChildren} from "Api/Query/ChildrenQuery";
import {deleteChildren} from "Api/Mutation/ChildMutation";

export const AdminKidsList: React.FC = () => {

    const headers: string[] = [
        'NOMBRE NIÑO/A',
        'DNI/NIE',
        'Nº NIÑO',
        'CENTRO SELECCIONADO',
        'ACCIONES'
    ]

    return (
        <RequireAuth>

            <AdminCrudList
                title={"Listado de Niños/as"}
                headers={headers}
                queryMethod={getChildren}
                queryName={"adminListChildren"}
                deleteMethod={deleteChildren}
                tableRow={(item: any, key: number) =>
                    <>
                        <td>
                            <Link to={ROUTE_PATHS.ADMIN_KIDS_EDIT.replace(":id", item.id)}>
                                {item.name} {item.surnames}
                            </Link>
                        </td>
                        <td>{item.nif}</td>
                        <td>{item.number}</td>
                        <td>{item.center}</td>
                        <td className={"text-center align-middle"}>
                            <Button
                                className={"purple-btn btn-sm"}
                                href={ROUTE_PATHS.ADMIN_KIDS_EDIT.replace(":id", item.id)}
                            >
                                Modificar
                            </Button>
                        </td>
                    </>
                }
                createButton={
                    <>
                        {/*<Button*/}
                        {/*    className={"purple-btn"}*/}
                        {/*    href={"#"}*/}
                        {/*>Añadir niño</Button>*/}
                    </>
                }
            />

        </RequireAuth>
    )
}
