import React, {useEffect, useState} from "react";
import {Dropzone, FileItem} from "@dropzone-ui/react";
import {http} from "Services/Http/HttpClient"

export interface DropzoneFile {
    errors: any[]
    file: File
    id: number
    name: string
    valid: boolean
    isImage?: boolean
    url?: string
    deleteUrl?: string
}

interface Props {
    onChange: (files: DropzoneFile[]) => void,
    maxFiles?: number
    maxFileSize?: number
    defaultFiles?: DropzoneFile[],
    hasError?: boolean,
    allowedFilesText?: string
}

export const FileUpload: React.FC<Props> = (props) => {

    const DEFAULT_MAX_FILES = 10
    const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024
    const [files, setFiles] = useState<any[]>([]);

    const acceptedMimmeTypes = "image/*,application/pdf"

    const updateFiles = (incommingFiles) => {
        setFiles(incommingFiles)
        props.onChange(incommingFiles)
    }

    const onDelete = (id) => {
        const fileToDelete: any[] = files.filter((x) => x.id === id)

        if (typeof fileToDelete[0].deleteUrl !== 'undefined') {
            // remove from database
            http.delete(fileToDelete[0].deleteUrl)
        }

        const newFiles = files.filter((x) => x.id !== id)
        setFiles(newFiles)
        props.onChange(newFiles)
    }

    useEffect(() => {
        setFiles(props.defaultFiles ?? [])
    }, [props.defaultFiles]);

    return (
        <>
            <Dropzone
                onChange={updateFiles}
                value={files}
                accept={acceptedMimmeTypes}
                label={"Arrastra aquí los archivos o haz click"}
                minHeight={"195px"}
                maxHeight={"500px"}
                localization={"ES-es"}
                behaviour={"add"}
                disableScroll
                footer={false}
                maxFiles={props.maxFiles ?? DEFAULT_MAX_FILES}
                maxFileSize={props.maxFileSize ?? DEFAULT_MAX_FILE_SIZE}
                style={props.hasError === true ? {'border': '2px solid red'} : {}}
            >
                {files.length > 0 && files.map((file) => (
                    <FileItem
                        {...file}
                        key={file.id}
                        onDelete={onDelete}
                        alwaysActive
                        localization={"ES-es"}
                        preview
                        info
                        resultOnTooltip
                        imageUrl={file.isImage === true ? file.url : null}
                    />
                ))}
            </Dropzone>

            {
                props.allowedFilesText &&
                <small className={"paddingFileDropzoneFileTypeInfoText"}>{props.allowedFilesText}</small>
            }

            {
                files.length > 0 && <>
                    <br />
                    <i>Previsualización de archivos:</i>
                    <ul>
                        {
                            files.map((f:any, key:number) => <li key={key}>
                                <a href={f.url} target="_blank">{f.name}</a>
                            </li>)
                        }
                    </ul>
                </>
            }

        </>
    );

}
