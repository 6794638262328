import React from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {AdminCrudList} from "../Shared/Crud/AdminCrudList";
import {getCenters} from "Api/Query/CenterQuery";
import {deleteCenters} from "Api/Mutation/CenterMutation";
import {Link} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {Button} from "react-bootstrap";
import {getTutors} from "../../Api/Query/TutorQuery";

export const AdminTutorList : React.FC = () => {

    const headers: string[] = [
        'NOMBRE',
        'APELLIDOS',
        'HIJOS',
        'EMAIL',
    ]

    return (
        <RequireAuth>
            <AdminCrudList
                title={"Listado de tutores"}
                headers={headers}
                queryMethod={getTutors}
                queryName={"adminListTutors"}
                canSelectMultipleRows={false}
                deleteMethod={() => console.log(1)}
                tableRow={(item: any, key: number) =>
                    <>
                        <td>
                            {/*<Link to={ROUTE_PATHS.ADMIN_CENTERS_EDIT.replace(":id", item.id)}>*/}
                            {item.name}
                            {/*</Link>*/}
                        </td>
                        <td>{item.surnames}</td>
                        <td>
                            <ul>
                                {
                                    item.children.map((c: any) => {
                                        return (
                                            <li>{c.name} {c.surnames}</li>
                                        )
                                    })
                                }
                            </ul>
                        </td>
                        <td>{item.user.email}</td>
                        <td className={"text-center align-middle"}>
                            <Button
                                className={"purple-btn btn-sm"}
                                href={'#'}
                            >
                                Modificar
                            </Button>
                        </td>
                    </>
                }
            />

        </RequireAuth>
    )

}